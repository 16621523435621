import React from "react"
import Title from "./Title"
import {
  MdDateRange,
  FaCalendarAlt
} from "react-icons/fa"

const TableRow= ({program,school,year,link})=>{
    return(               
        <a href={link} target="_blank">
        <div className="edu">
            <p className="edu-date"><FaCalendarAlt className="social-icon"></FaCalendarAlt><span> {year}</span></p>
            <h4 className="edu-program-name">
                {program}
            </h4>
        </div>
        </a>
        
    );
}

const Certification = () => {
  return (
    <section className="section section-center bg-grey">
        <Title title="Certifications"/>
        <div>
            
                <TableRow 
                    program="AWS Certified Cloud Practitioner - 2021"
                    year="April 22, 2021 - May 26, 2021"
                    link="https://www.udemy.com/course/aws-certified-cloud-practitioner-new/"
                />
                <TableRow 
                    program="FIU CONNECT (Human Trafficking)"
                    year="May 02, 2021 - May 16, 2021"
                    link="https://www.credly.com/badges/3fb040a8-d350-49bb-af2f-afbcbb7a8d60/linked_in"
                />
        </div>
        
    </section>
  )
}


export default Certification;