import React from "react"
import Title from "./Title"
import {
  MdDateRange,
  FaCalendarAlt
} from "react-icons/fa"
const TableRow= ({program,school,year,link})=>{
    return(               
        <a href={link} target="_blank">
        <div className="edu">
            <p className="edu-date"><FaCalendarAlt className="social-icon"></FaCalendarAlt><span> {year}</span></p>
            <h4 className="edu-program-name">
                {program}
            </h4>
            <p className="edu-program-name">{school}</p>
        </div>
        </a>
        
    );
}
const Education = () => {
  return (
    <section className="section section-center bg-grey">
        <Title title="Education"/>
        <div>
            
                <TableRow 
                    program="Diploma in IT Web Programming"
                    school="Nova Scotia Community College"
                    year="Sept 2019 - May 2021"
                    link="https://www.nscc.ca/learning_programs/programs/plandescr.aspx?prg=ITWP&pln=ITWEBPRO"
                />
                 <TableRow 
                    program="Diploma in Business Administration"
                    school="Mount Saint Vincent University"
                    year="Sept 2018 - May 2019"
                    link="https://www.msvu.ca/academics/diploma-certificates/diploma-in-business-administration/"
                />
        </div>
        
    </section>
  )
}


export default Education